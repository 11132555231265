import clsx from 'clsx'

import { PAGE_ROUTES } from '~/constants'
import Text from '~/components/Text/Text'
import Button from '~/components/Button/Button'

import styles from './NotFoundHero.module.sass'

const shapeAltText = "Seems we've lost our flow. 404"

const NotFoundHero: React.FC = () => {
  return (
    <div className={clsx('site-grid site-container', styles.section)}>
      <div className='flex justify-center col-span-2 md:hidden'>
        <img src='/Images/404-shape.svg' alt={shapeAltText} className={styles.shape} />
      </div>

      <div className='hidden lg:hidden md:flex md:justify-center md:col-start-2 md:col-end-8'>
        <img src='/Images/404-shape-tablet.svg' alt={shapeAltText} className={styles.shape} />
      </div>

      <div className='hidden lg:flex lg:col-start-4 lg:col-end-10'>
        <img src='/Images/404-shape-desktop.svg' alt={shapeAltText} className={styles.shape} />
      </div>

      <div
        className={clsx('col-span-full md:col-start-3 md:col-end-7 lg:col-start-5 lg:col-end-9', styles.textSection)}
      >
        <Text fontSize={[18, 14, 18]} className={styles.description}>
          The requested page doesn’t exist.
        </Text>
      </div>

      <div
        className={clsx(
          'flex justify-center col-span-full md:col-start-4 md:col-end-6 lg:col-start-6 lg:col-end-8',
          styles.textSection
        )}
      >
        <Button link={PAGE_ROUTES.HOME} className={styles.button}>
          Take me home
        </Button>
      </div>
    </div>
  )
}

export default NotFoundHero
