import { GetStaticProps } from 'next'

import { Layout } from '~/components/Layout'
import { getLayoutProps } from '~/lib/layout'
import { PageProps } from '~/lib/types'
import NotFoundHero from '~/sections/NotFoundHero/NotFoundHero'

export const getStaticProps: GetStaticProps = async () => ({
  props: {
    layoutProps: getLayoutProps(),
  },
})

const NotFound: React.FC<PageProps> = ({ layoutProps }) => {
  return (
    <Layout {...layoutProps} title='404 Page Not Found'>
      <NotFoundHero />
    </Layout>
  )
}

export default NotFound
